import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactStateLegislators from "../components/contact-state-legislators"

export default function MoreInfo() {
  const links = [
    {
      url: "https://www.livablecalifornia.org/",
      text: "Livable California (Open to anyone interested)",
    },
    {
      text: `CALE - California Alliance of Local Electeds (Primarily intended for
        Elected Officials)`,
      url: "https://www.caleelecteds.org/",
    },
    {
      text: "United Neighbors",
      url: "/documents/united-neighbors.pdf",
    },
    {
      text: "Community CATALYSTS for Local Control",
      url: "https://catalystsca.org/",
    },
    {
      text: "Our Neighborhood Voices",
      url: "https://ourneighborhoodvoices.com/",
    },
  ]
  return (
    <Layout>
      <SEO title="More Info" />
      <h1>More Info</h1>
      <p>
        For those wanting more information on various bills and to participate
        in regular discussion on these important issues, please considering
        signing up with either or both of the following groups which are doing
        great work to help protect Cities from overreach by the State:
      </p>
      <ul className="ml-5 list-disc list-outside">
        {links.map(({ text, url }) => {
          return (
            <li key={url}>
              <a href={url} target="_blank" rel="noreferrer">
                {text}
              </a>
            </li>
          )
        })}
      </ul>
      <p>The more you get involved, the better we hope the results will be!</p>
      <ContactStateLegislators />
    </Layout>
  )
}
